@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_HISTORY__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_PRICE_DEVIATION_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ALLOWABLE_REFUND__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
/* stylelint-disable declaration-no-important */
@import '../../shared/scss/_dt-base.scss';

.label {
  pointer-events: none;
}

.input {
  :global {
    .input-container {
      overflow: visible;
    }

    .input-container--focused {
      border: 1px solid transparent;
    }

    .simple-select {
      .react-selectize-search-field-and-selected-values.react-selectize-search-field-and-selected-values {
        font-size: rem(16);
        line-height: rem(24);
        font-weight: $normal;
        width: calc(100% - 42px);

        &:focus-within + .react-selectize-toggle-button-container {
          border: 1px solid $gold-500;
          border-left: none;
          border-radius: 3px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .dropdown-menu {
        .simple-option {
          font-size: rem(16);
          line-height: rem(24);
          font-weight: $normal;
        }
      }
    }
  }
}

.input.open {
  :global .input-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.flipped {
    :global .input-container {
      border-radius: 4px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

:global .react-selectize {
  border-radius: 4px;

  &.default.root-node {
    .react-selectize-control {
      height: 48px;
      .react-selectize-placeholder {
        color: $medium-light-gray;
        cursor: default;
        display: block;
        max-width: calc(100% - 10px);
        overflow: visible;
        padding: 13px 23px;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .react-selectize-search-field-and-selected-values {
        padding: initial;
        position: relative;

        .resizable-input {
          padding: 0;
          padding-left: calc(20px + 2px);
          border: none;
          border-radius: 3px;
          position: absolute;
          top: -1px;
          left: -1px;
          height: 48px; // for IE absolute positioning
          background-color: transparent;
          width: calc(
            100% + 2px
          ) !important; // override react-selectize inline width
          cursor: pointer;
          box-sizing: border-box;
          font-weight: $normal;
        }

        .resizable-input:focus {
          cursor: auto;
          outline: none;
          border: 1px solid $gold-500;
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .value-wrapper {
          padding-left: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;
          color: $black;

          .simple-value {
            display: flex;
            overflow: hidden;

            > span {
              display: inline-block;
              vertical-align: middle;
              overflow: hidden;
              max-width: 101%;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .react-selectize-toggle-button-container {
        height: 48px;
        width: 42px;
        top: -1px;
        right: -1px;

        .select-toggle {
          margin-left: -8px;
          font-size: rem(28);
          color: $grey-500;
        }
      }
    }
    &.open {
      .react-selectize-control {
        .react-selectize-search-field-and-selected-values {
          .resizable-input {
            outline: none;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }

          &:focus-within + .react-selectize-toggle-button-container {
            border-bottom-right-radius: 0;
          }
        }
      }

      &.flipped {
        .react-selectize-control {
          .react-selectize-search-field-and-selected-values {
            .resizable-input {
              border-radius: 3px;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            &:focus-within + .react-selectize-toggle-button-container {
              border-top-right-radius: 0;
              border-bottom-right-radius: 3px;
            }
          }
        }
      }

      .dropdown-menu {
        top: 48px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;

        &.flipped {
          top: 0;
          border-radius: 4px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
          border-top: 1px solid $grey-500;
        }
      }
    }
  }

  &.dropdown-menu {
    position: absolute;
    top: 47px;
    max-height: 200px;
    width: 100%;
    overflow: auto;
    margin-left: -1px;
    background: $white;
    border: 1px solid $grey-500;
    border-radius: 4px;
    padding: 2px 0 4px;
    z-index: 5;

    &.flipped {
      transform: translateY(-100%);
      top: 1px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.default {
      .option-wrapper {
        font-weight: $normal;
        border: none;
        cursor: pointer;
        color: $black;

        .simple-option {
          padding: 9px 24px;

          &.not-selectable {
            background-color: $grey-100;
            color: $grey-500;
            cursor: default;
          }
        }

        &.highlight {
          color: $blue-700;
        }

        &:last-child {
          border: 0;
        }
      }

      .no-results-found {
        padding: 13px;
      }
    }
  }
}
