@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_HISTORY__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_PRICE_DEVIATION_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ALLOWABLE_REFUND__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/dt-base.scss';

.back-button {
  margin-bottom: -20px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;

  :global .fitment-crumbs {
    margin-bottom: 16px;

    @include media($min-sm) {
      margin: 0;
    }

    :global .vehicle-description {
      @include media($min-md) {
        font-size: rem(16);
      }

      @include media($min-lg) {
        font-size: rem(18);
      }
    }

    :global .vehicle-description__year-make {
      font-size: rem(16);

      @include media($min-sm) {
        font-size: rem(18);
      }

      @include media($min-md) {
        font-size: rem(22);
      }

      @include media($min-lg) {
        font-size: rem(24);
      }
    }

    :global .vehicle-description__model-trim-container {
      @include media($min-md) {
        max-width: 185px;
      }

      @include media($min-lg) {
        max-width: 204px;
      }
    }
  }
}

.header-empty {
  @extend .header;

  :global .fitment-crumbs {
    margin: 0;
  }

  @include media($min-sm) {
    flex-direction: row-reverse;
    margin-bottom: -40px;
  }
}

.selection-crumbs {
  align-items: flex-start;
  flex-direction: column;
  flex-direction: row-reverse;
  flex-grow: 1;
  justify-content: space-between;

  @include media($min-sm) {
    align-items: center;
    display: flex;
    align-items: flex-start;
  }
}

.help-me-choose-container {
  margin-top: auto;
  padding-right: 20px;
}

.fitment-select-tabs {
  padding: 20px 0 0 0;

  .add-vehicle-button {
    padding: 0 0 10px 15px;
  }
}

.vehicle-selection-text {
  padding: 24px 20px 0 0;
  @include media($min-sm) {
    padding: 24px 20px 20px 0;
  }
}

.show-all-container {
  margin: 0 -40px;
  text-align: center;
  padding: 15px;
  border-top: 1px solid $light-gray;
}

.show-all-button {
  margin: auto;
  text-align: center;
}
